import "./src/styles/fonts/fonts.css"

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (
    typeof window !== `undefined` &&
    location.pathname !== prevLocation?.pathname
  ) {
    window.scrollTo(0, 0)
  }
}
